(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){


var winht = jQuery(window).height(),
  winhwt = jQuery(window).width();

var frameNumber;
var windowSize = jQuery(window).width();

var frameCount, imageFolder, macFolder;

if (windowSize > 991) {
  frameCount = 980;
  imageFolder = 'vero-image-webp';
  macFolder = 'vero-image-png';
}
else {
  frameCount = 932;
  imageFolder = 'vero-image-mob-webp';
  macFolder = 'vero-image-mob-png';
}

if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
  var currentFrame = index => (
    `./wp-content/themes/enfold-child/${macFolder}/${(index + 1).toString().padStart(4, '0')}.png`
  );
}
else {
  var currentFrame = index => (
    `./wp-content/themes/enfold-child/${imageFolder}/${(index + 1).toString().padStart(4, '0')}.webp`
  );
}


const images = [];
for (let i = 0; i < frameCount; i++) {
  const img = new Image();
  img.src = currentFrame(i);
  images.push(img);
}

// For responsive
function updateStats(thisFrame ) {
  jQuery(".frameCount").html('frame: ' + thisFrame);

  if (thisFrame >= 300 && thisFrame <= 600) {
    jQuery(".section-animation canvas").addClass("change-to-middle");
  }
  else if (thisFrame >= 795 && thisFrame <= 932) {
    jQuery(".section-animation canvas").addClass("cassette");
  }
  else if (thisFrame >= 795 && thisFrame <= 830) {
    jQuery(".section-animation canvas").addClass("fade-out");
    jQuery(".section-animation canvas").removeClass("fade-in");
  }

  else if (thisFrame >= 831 && thisFrame <= 927) {
    jQuery(".section-animation canvas").addClass("fade-in");
  }
 
  else if (thisFrame >= 927 && thisFrame <= 932 ) {
    jQuery(".section-animation canvas").removeClass("fade-in");
  }
  else {
    jQuery(".section-animation canvas").removeClass("change-to-middle");
    jQuery(".section-animation canvas").removeClass("fade-in");
    jQuery(".section-animation canvas").removeClass("fade-out");
    jQuery(".section-animation canvas").removeClass("cassette");
  }
}


jQuery(window).on('load', function () {
    setTimeout(function(){
      // document.querySelector('.section-video video').setAttribute('autoplay', true);
      // document.querySelector('.section-video video').play();
      // document.querySelector('.section-video video').setAttribute('style', 'display: block');

      document.querySelector('.section-video video').playbackRate = 1.5;
    }, 200);


    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({

      // all
      "all": function () {
        console.clear();

        if (windowSize >= 992) {
          frameNumber = 7;
          jQuery('.section-animation').css('margin-top', 0);
          jQuery('.section-animation').css({'height': frameCount * 7.5 });
          jQuery('.container-hero').css('height', frameCount * 7);
        }
        else if (windowSize >= 768 && windowSize <= 991) {
          frameNumber = 8;
          jQuery('.section-animation').css('margin-top', - winht/2);
          jQuery('.section-animation').css('height', frameCount * 8.5);
          jQuery('.container-hero').css('height', winht * 8);
        }
        else {
          // For mobile 
          frameNumber = 4.5;
          jQuery('.section-animation').css('margin-top', 0 );
          // $('.section-animation').css('height', '5880px');
          jQuery('.section-animation').css('height', '4500px');
          jQuery('.container-hero').css('height', '4002px');
        }

        if (windowSize >= 2500) {
          jQuery('.section-animation').css('margin-top', '350px');
        }
        const canvas = document.getElementById("hero-lightpass");
        const context = canvas.getContext("2d");

        var imageObj = new Image();

        if (windowSize >= 2560) {
          // canvas.width = winhwt;
          // canvas.height = winht;

          var mh = ((winhwt * 9) / 16)
          canvas.width = winhwt;
          canvas.height = mh;
        }
        else if (windowSize > 991 && windowSize <= 2559) {
          // canvas.width = 2560;
          // canvas.height = 1440;

          // canvas.width = winhwt;
          // canvas.height = winht;

          var mh = ((winhwt * 9) / 16)
          canvas.width = winhwt;
          canvas.height = mh;
        }
        else {
          // canvas.width = 991;
          // canvas.height = 557;
          // var mh = ((winhwt * 9) / 16)
          // canvas.width = winhwt;
          // canvas.height = mh;

          canvas.width = 1280;
          canvas.height = 720;
        }

        const airpods = {
          frame: 0
        };

        var canvasOffset = jQuery('.section-animation').offset();
        var canvasOffsetTop = canvasOffset.top -winht /1.4;

        var abc;
        if (windowSize >= 992) {
          abc = render;
        }else {
          abc = rendermob;
        }

        gsap.to(airpods, {
          frame: frameCount - 1,
          snap: "frame",
          ease: "steps(" + frameCount + ")", // use a stepped ease for the sprite sheet
          scrollTrigger: {
            trigger: "#sec02",
            scrub: 0.75,
            start: canvasOffsetTop,
            end: "+=" + (frameCount * frameNumber),
          },
          onUpdate: abc
        });

        images[0].onload = abc;

        function render() {

          var imgWidth = 1280;
          var imgHeight = 720;

          var canvas = context.canvas ;
          var hRatio = canvas.width  / imgWidth;
          var vRatio =  canvas.height / imgHeight;
          var ratio  = Math.min ( hRatio, vRatio );
          var centerShift_x = ( canvas.width - imgWidth*ratio ) / 2;
          var centerShift_y = ( canvas.height - imgHeight*ratio ) / 2;  
          context.clearRect(0,0,canvas.width, canvas.height);

          context.drawImage(images[airpods.frame], 0,0, imgWidth, imgHeight,
                      centerShift_x,centerShift_y,imgWidth*ratio, imgHeight *ratio);

          updateStats(airpods.frame,);


          // context.clearRect(0, 0, canvas.width, canvas.height);
          // context.drawImage(images[airpods.frame], 0, 0, canvas.width, canvas.height);
          // updateStats(airpods.frame,);
        }

        function rendermob() {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(images[airpods.frame], 0, 0, canvas.width, canvas.height);
          updateStats(airpods.frame,);
        }


          // Timeline for fading in and fading out the text
        var targets1 = document.querySelector(".container-hero div.title1");
        var targets6 = document.querySelectorAll(".container-hero div.title6");
        var targets2 = document.querySelectorAll(".container-hero div.title2");
        var targets3 = document.querySelectorAll(".container-hero div.title3");
        var targets4 = document.querySelectorAll(".container-hero div.title4");
        var targets5 = document.querySelectorAll(".container-hero div.title5");
        var targets4_blank = document.querySelectorAll(".container-hero div.title-4-blank");
        var targets1_blank = document.querySelector(".container-hero div.title-gap-1");

        if (windowSize < 992) {
          const t1_blank = gsap.timeline({
            defaults: { duration: 1 },
            scrollTrigger: {
              trigger: targets1_blank,
              // markers: true,
              scrub: 0.5,
              start: "top 90%",
              end: "bottom 300px",
              pin: true
            }
          })
          .fromTo(targets1_blank, { x: 0 }, { y: -winht / 1.2 })
          .from(targets1_blank, { opacity: 0, duration: 0.4 }, 0)
          .to(targets1_blank, { opacity: 0, duration: 0.4 }, 0.8)
        }

        // For scene 1
        var xPosistion_tl1, yPosistion_tl1;
        var end_point_tl1;
        var delay_to_tl1;

        if (windowSize >= 2000) {
          xPosistion_tl1 = 700;
          yPosistion_tl1 = -winht /2.9;
          delay_to_tl1 = 4;
          end_point_tl1 = "bottom 250";
        }
        else if (windowSize >= 1650) {
          xPosistion_tl1 = 550;
          yPosistion_tl1 = -winht /2.5;
          delay_to_tl1 = 2;
          end_point_tl1 = "bottom 240";
        }
        else if (windowSize >= 1500) {
          xPosistion_tl1 = 500;
          yPosistion_tl1 = -winht /2.5;
          delay_to_tl1 = 2;
          end_point_tl1 = "bottom 240";
        }
        else if (windowSize >= 1400) {
          xPosistion_tl1 = 800;
          yPosistion_tl1 = -winht /2.7;
          delay_to_tl1 = 2;
          end_point_tl1 = "bottom 250";
        }
        else if (windowSize >= 1360) {
          xPosistion_tl1 = 700;
          yPosistion_tl1 = -winht /2.7;
          delay_to_tl1 = 2;
          end_point_tl1 = "bottom 250";
        }
        else if (windowSize >= 1025) {
          xPosistion_tl1 = 680;
          yPosistion_tl1 = -winht /2.7;
          delay_to_tl1 = 2;
          end_point_tl1 = "bottom 200";
        }
        else if (windowSize >= 992) {
          xPosistion_tl1 = 200;
          yPosistion_tl1 = -winht /2.5;
          delay_to_tl1 = 4;
          end_point_tl1 = "bottom 200";
        }
        else {
          xPosistion_tl1 = 0;
          yPosistion_tl1 = -winht /2.4;
          delay_to_tl1 = 5;
          end_point_tl1 = "bottom 50";
        }

        const tl1 = gsap.timeline({
          defaults: { duration: 1 },
          repeatDelay: 1,
          scrollTrigger: {
            trigger: targets1,
            scrub: 0.5,
            // markers:  {startColor: "yellow", endColor: "white", fontSize: "30px"},
            start: "top 60%",
            end: end_point_tl1,
            pin: true
          }
        })
          .fromTo(targets1, { x: xPosistion_tl1 }, { y: yPosistion_tl1 })
          .from(targets1, { opacity: 0, duration: 0.2 }, 0)
          .to(targets1, { opacity: 0, duration: 0.2 }, delay_to_tl1)

        const tl6 = gsap.timeline({
          defaults: { duration: 1 },
          scrollTrigger: {
            trigger: targets6,
            // markers:  {startColor: "yellow", endColor: "white", fontSize: "30px"},
            scrub: 0.5,
            start: "center 80%",
            end: "bottom top",
            pin: true
          }
        })
          .fromTo(targets6, { y: winht / 2 }, { y: -winht / 2 })
          .from(targets6, { opacity: 0, duration: 0.2 }, 0)
          .to(targets6, { opacity: 0, duration: 0.2 }, 0.8)

        // For scene 2
      var yPosistion_tl2, end_point_tl2,start_point_tl2, delay_to_tl2;

      if (windowSize >= 2000) {
        yPosistion_tl2 = -winht / 3.45;
        start_point_tl2 = "top 60%";
        end_point_tl2 = "bottom 60%";
        delay_to_tl2 = 3;
      }
      else if (windowSize >= 1500) {
        yPosistion_tl2 = -winht / 2.8;
        start_point_tl2 = "top 55%";
        end_point_tl2 = "bottom 200";
        delay_to_tl2 = 2;
      }
      else if (windowSize >= 1360) {
        yPosistion_tl2 = -winht / 3;
        start_point_tl2 = "top 50%";
        end_point_tl2 = "bottom 25%";
        delay_to_tl2 = 4;
      }
      else if (windowSize >= 1025) {
        yPosistion_tl2 = -winht / 3;
        start_point_tl2 = "top 50%";
        end_point_tl2 = "bottom 24%";
        delay_to_tl2 = 4;
      }
      else if (windowSize >= 992) {
        yPosistion_tl2 = -winht / 2.7;
        start_point_tl2 = "top 50%";
        end_point_tl2 = "bottom 25%";
        delay_to_tl2 = 4;
      }

      // if (windowSize >= 992) {
      //   yPosistion_tl2 = -winht / 2.4;
      //   start_point_tl2 = "top 50%";
      //   end_point_tl2 = "bottom 60%";
      //   delay_to_tl2 = 5.5;
      // }

      else {
        yPosistion_tl2 = -winht/1.4;
        end_point_tl2 = "top 5%";
        start_point_tl2 = "top 90%";
        delay_to_tl2 = 3;
      }

      const tl2 = gsap.timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: targets2,
          // markers:  {startColor: "red", endColor: "red", fontSize: "20px"},
          scrub: 0.5,
          start: start_point_tl2,
          end: end_point_tl2,
          pin: true
        }
      })
        .fromTo(targets2, { y: winht / 2 }, { y: yPosistion_tl2 })
        .from(targets2, { opacity: 1, duration: 0.2 }, 0)
        .to(targets2, { opacity: 0, duration: 0.2 }, delay_to_tl2)


      // screen-3
      var yPosistion_tl3, end_point_tl3, start_point_tl3, delay_to_tl3;

      if (windowSize >= 2000) {
        yPosistion_tl3 = -winht / 2.8;
        start_point_tl3 = "top 66%";
        end_point_tl3 = "top 0";
        delay_to_tl3 = 3;
      }
      else if (windowSize >= 1500) {
        yPosistion_tl3 = -winht / 3.2;
        start_point_tl3 = "top 50%";
        end_point_tl3 = "bottom 200";
        delay_to_tl3 = 3;
      }
      else if (windowSize >= 1360) {
        yPosistion_tl3 = -winht / 3;
        start_point_tl3 = "top 50%";
        end_point_tl3 = "bottom 25%";
        delay_to_tl3 = 4;
      }
      else if (windowSize >= 1025) {
        yPosistion_tl3 = -winht / 3;
        start_point_tl3 = "top 50%";
        end_point_tl3 = "bottom 24%";
        delay_to_tl3 = 4;
      }
      else if (windowSize >= 992) {
        yPosistion_tl3 = -winht / 2.9;
        start_point_tl3 = "top 50%";
        end_point_tl3 = "bottom 25%";
        delay_to_tl3 = 4;
      }
      else {
        yPosistion_tl3 = -winht / 3.5;
        start_point_tl3 = "top 50%";
        end_point_tl3 = "bottom 180";        
        delay_to_tl3 = 3;
      }       
      const tl3 = gsap.timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: targets3,
          // markers:  {startColor: "pink", endColor: "pink", fontSize: "30px"},
          scrub: 0.5,
          start: start_point_tl3,
          end: end_point_tl3,
          pin: true
        }
      })
        .fromTo(targets3, { y: winht / 2 }, { y: yPosistion_tl3})
        .from(targets3, { opacity: 0, duration: 0.2 }, 0)
        .to(targets3, { opacity: 0, duration: 0.2 }, delay_to_tl3)

          //title no-4 blank
          if (windowSize >= 2000) {
            const tl4_blank = gsap.timeline({
              defaults: { duration: 1 },
              scrollTrigger: {
                trigger: targets4_blank,
                // markers: true,
                scrub: 0.5,
                start: "top 90%",
                end: "bottom 300px",
                pin: true
              }
            })
              .fromTo(targets4_blank, { x: 0 }, { y: -winht / 1.2 })
              .from(targets4_blank, { opacity: 0, duration: 0.4 }, 0)
              .to(targets4_blank, { opacity: 0, duration: 0.4 }, 0.8)
          }


        // For scene 4
        var xPosistion_tl4, delay_to_tl4 , end_point_tl4, yPosistion_tl4;
        if (windowSize >= 2200) {
          xPosistion_tl4 = 750;
          end_point_tl4 = "top 0";
          delay_to_tl4 = 2;
          yPosistion_tl4 = -winht / 1.85;
        }
        else if (windowSize >= 2000) {
          xPosistion_tl4 = 700;
          end_point_tl4 = "top 0";
          delay_to_tl4 = 2;
          yPosistion_tl4 = -winht / 1.9;
        }
        else if (windowSize >= 1700) {
          xPosistion_tl4 = 550;
          end_point_tl4 = "top 0";
          delay_to_tl4 = 3;
          yPosistion_tl4 = -winht / 1.6;
        }
        else if (windowSize >= 1500) {
          xPosistion_tl4 = 450;
          end_point_tl4 = "top 0";
          delay_to_tl4 = 3;
          yPosistion_tl4 = -winht / 1.6;
        }
        else if (windowSize >= 1360) {
          xPosistion_tl4 = 650;
          end_point_tl4 = "top 0";
          delay_to_tl4 = 4;
          yPosistion_tl4 = -winht / 1.5;
        }
        else if (windowSize >= 1280) {
          xPosistion_tl4 = 680;
          end_point_tl4 = "top 0";
          delay_to_tl4 = 4;
          yPosistion_tl4 = -winht / 1.6;
        }
        else if (windowSize >= 1025) {
          xPosistion_tl4 = 600;
          end_point_tl4 = "top 0";
          delay_to_tl4 = 4;
          yPosistion_tl4 = -winht / 1.6;
        }
        else if (windowSize >= 992) {
          xPosistion_tl4 = 400;
          end_point_tl4 = "top 0";
          delay_to_tl4 = 4;
          yPosistion_tl4 = -winht / 1.6;
        }
        else {
          xPosistion_tl4 = 0;
          end_point_tl4 = "bottom 70%";
          delay_to_tl4 = 3;
          yPosistion_tl4 = -winht / 1.8;
        }

        const tl4 = gsap.timeline({
          defaults: { duration: 1 },
          scrollTrigger: {
            trigger: targets4,
            // markers: true,
            // markers:  {startColor: "red", endColor: "white", fontSize: "30px"},
            scrub: 0.5,
            start: "top 80%",
            end: end_point_tl4,
            pin: true
          }
        })
          .fromTo(targets4, { x: xPosistion_tl4 }, { y: yPosistion_tl4 })
          .from(targets4, { opacity: 0, duration: 0.2 }, 0)
          .to(targets4, { opacity: 0, duration: 0.2 }, delay_to_tl4)

        // For scene 5
        var xPosistion_tl5, delay_to_tl5 , end_point_tl5, yPosistion_tl5;

        if (windowSize >= 2000) {
          xPosistion_tl5 = 700;
          delay_to_tl5 = 2;
          yPosistion_tl5 = -winht /1.7;
          end_point_tl5 = "bottom 250"
        }
        else if (windowSize >= 1700) {
          xPosistion_tl5 = 550;
          delay_to_tl5 = 3;
          yPosistion_tl5 = -winht /1.5;
          end_point_tl5 = "bottom 250"
        }
        else if (windowSize >= 1500) {
          xPosistion_tl5 = 450;
          delay_to_tl5 = 3;
          yPosistion_tl5 = -winht /1.5;
          end_point_tl5 = "bottom 250"
        }
        else if (windowSize >= 1360) {
          xPosistion_tl5 = 650;
          delay_to_tl5 = 3;
          yPosistion_tl5 = -winht /1.5;
          end_point_tl5 = "bottom 250"
        }
        else if (windowSize >= 1260) {
          xPosistion_tl5 = 650;
          delay_to_tl5 = 3;
          yPosistion_tl5 = -winht /1.5;
          end_point_tl5 = "bottom 250"
        }
        else if (windowSize >= 1025) {
          xPosistion_tl5 = 500;
          delay_to_tl5 = 3;
          yPosistion_tl5 = -winht /1.5;
          end_point_tl5 = "bottom 250"
        }
        else if (windowSize >= 992) {
          xPosistion_tl5 = 380;
          delay_to_tl5 = 3;
          yPosistion_tl5 = -winht /1.5;
          end_point_tl5 = "bottom 250"
        }
        else {
          xPosistion_tl5 = 0;
          delay_to_tl5 = 2;
          yPosistion_tl5 = -winht /1.55;
          end_point_tl5 = "bottom 100"
        }

        const tl5 = gsap.timeline({
          defaults: { duration: 1 },
          scrollTrigger: {
            trigger: targets5,
            // markers: true,
            // markers:  {startColor: "red", endColor: "white", fontSize: "30px"},
            scrub: 0.5,
            start: "top 80%",
            end: end_point_tl5,
            pin: true
          }
        })
          .fromTo(targets5, { x: xPosistion_tl5 }, { y: yPosistion_tl5 })
          .from(targets5, { opacity: 0, duration: 0.2 }, 0)
          .to(targets5, { opacity: 0, duration: 0.2 }, delay_to_tl5)
      },

    });
});
},{}]},{},[1])